import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { EventsapiService } from '../eventsapi.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-viewevent',
  templateUrl: './viewevent.component.html',
  styleUrls: ['./viewevent.component.css']
})
export class VieweventComponent implements OnInit {

  eventID: any;
  event: any;
  updatedStatusValue: any;
  isAdmin: any;
  liveurl: any;

  constructor(private auth: AuthenticationService, private eventsapi: EventsapiService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.eventID = '';
    this.event = '';
    this.isAdmin = '';
    this.liveurl = 'https://otayo.com/';
  }

  ngOnInit() {
    let userRole = this.auth.getRole();
    if(userRole != 'administrator') {
      // this.router.navigateByUrl('/profile');
      this.isAdmin = false;
    } else {
      this.isAdmin = true;
    }
    this.activatedRoute.params.subscribe(paramsId => {
      this.eventID = paramsId.id;
      console.log(this.eventID);
      this.eventsapi.getEvent(this.eventID).then(data => {
        this.event = data;
        this.event.liveurl = '';
        this.updatedStatusValue = this.event.status;
        console.log(this.event);
      }).catch(error => {
        console.log("error fetching event");
      });
    });
  }

  updateStatus() {
    this.eventsapi.updateStatus(this.eventID, {status : this.updatedStatusValue}).then(data => {
      console.log(data);
      this.event.status = this.updatedStatusValue;
      Swal.fire('Updated', 'Status of the Event has been updated', 'success');
    }).catch(error => {
      console.log(error);
      Swal.fire('Oops...', 'Something went wrong! Please check your data and try again', 'error');
    });
    console.log(this.updatedStatusValue);
  }

  formatDate(date) {
    let newdate = moment(date).add(4, 'hours').format('YYYY/MM/DD');
    this.event.eventdate = newdate;
    return newdate;

  }
  formatDate2(date) {
    let newdate = moment(date).add(4, 'hours').format('YYYY/MM/DD');
    this.event.eventenddate = newdate;
    return newdate;

  }
  formatTime(time){
    let newtime = moment(time).format('hh:mm A').toString();
    // this.event.eventtime = newtime.toString();
    return newtime;
  }

  pushToWebsite() {
    this.eventsapi.pushToWebsite(this.event).then(data => {
      console.log(data);
      Swal.fire('Pushed', 'Event has been pushed to website. Please check in magento admin', 'success');
    }).catch(error => {
      console.log(error);
      Swal.fire('Oops...', 'Something went wrong! Please check your data and try again', 'error');
    });
  }

  decodeEventType(typeCode) {
    let types = [];
    types["Clubbing"] = "Clubbing";
    types["Concerts"] = "Concerts";
    types["Conference"] = "Conference";
    types["Dance"] = "Dance";
    types["Expositions"] = "Expositions";
    types["Festivals"] = "Festivals";
    types["Shows"] = "Shows";
    types["Sports"] = "Sports";
    types["Theaters"] = "Theaters";
    types["Workshops"] = "Workshops";
    types["Bien Etre"] = "Bien Etre";
    types["Movie Night / Cinema"] = "Movie Night / Cinema";
    types["Beach Party"] = "Beach Party";
    types["Exhibitions"] = "Exhibitions";

    return types[typeCode];
  }

  decodeEventLocation(locationCode) {
    let locations = [];
    locations["Centre"] = "Centre";
    locations["East"] = "East";
    locations["North"] = "North";
    locations["South"] = "South";
    locations["West"] = "West";

    return locations[locationCode];
  }

  sendEmailToProducer() {
    console.log('Email Sent to producer');
    this.event.liveurl = this.liveurl;
    console.log(this.event.liveurl);
    this.eventsapi.notifyProducer(this.event).then(data => {
      Swal.fire('Pushed', 'Notification has been sent to the producer', 'success');
    }).catch(error => {
      Swal.fire('Oops...', 'Something went wrong! Please check your data and try again', 'error');
    });
  }

}
