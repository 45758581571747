import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import { EventsapiService } from '../eventsapi.service';

import * as moment from 'moment';

@Component({
  selector: 'app-submittedevents',
  templateUrl: './submittedevents.component.html',
  styleUrls: ['./submittedevents.component.css']
})
export class SubmittedeventsComponent implements OnInit {

  allEvents: any;
  isAdmin: any;

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(private auth: AuthenticationService, private router: Router, private eventsapi: EventsapiService) {
    this.allEvents = '';
    this.isAdmin = false;
  }

  ngOnInit() {
    this.dtOptions = {
      dom: 'Bfrtip',
      order: [],
      pageLength: 20,
      buttons: ['copy', 'csv', 'excel', 'pdf', 'print']
    };
    let userRole = this.auth.getRole();
    let userName = this.auth.getProd();
    if(userRole != 'administrator') {
      this.isAdmin = false;
      // this.router.navigateByUrl('/profile');
      this.eventsapi.getAllEventsPerProducer(userName).then(data => {
        this.allEvents = data;
        this.allEvents = this.allEvents.reverse();
        console.log(this.allEvents);
      }).catch(error => {
        this.allEvents = '';
      });
    } else {
      this.isAdmin = true;
      this.eventsapi.getAllEvents().then(data => {
        this.allEvents = data;
        this.allEvents = this.allEvents.reverse();
        console.log(this.allEvents);
      }).catch(error => {
        this.allEvents = '';
      });
    }
  }

  viewevent(id) {
    console.log(id);
    this.router.navigateByUrl('/viewevent/' + id);
  }

  formatDate(date) {
    let newdate = moment(date).add(4, 'hours').format('YYYY/MM/DD');
    return newdate;
  }

}
