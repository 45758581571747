import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private token: string;
  private isvalid: boolean;
  private val: any;

  public producerInfo: any;
  public userRole: any;
  public UserDisplayName: any;
  public userEmail: any;

  constructor(private http: HttpClient, private router: Router) {
    this.val = {
      "auth": false,
      "token": null
    }

    this.producerInfo = '';
    this.userRole = '';
    this.UserDisplayName = '';
    this.userEmail = '';
  }

  private saveToken(token: string, prod: string, role: string, displayName: string, userEmail: string): void {
    localStorage.setItem('mean-token', token);
    localStorage.setItem('otayo-prod-name', prod);
    localStorage.setItem('otayo-user-role', role);
    localStorage.setItem('otayo-user-displayname', displayName);
    localStorage.setItem('otayo-user-useremail', userEmail);
    this.token = token;
  }

  public getToken(): string {
    if (!this.token) {
      this.token = localStorage.getItem('mean-token');
    }
    return this.token;
  }

  public getProd(): string {
    if (!this.producerInfo) {
      this.producerInfo = localStorage.getItem('otayo-prod-name');
    }
    return this.producerInfo;
  }
  public getRole(): string {
    if (!this.userRole) {
      this.userRole = localStorage.getItem('otayo-user-role');
    }
    return this.userRole;
  }
  public getUserDisplayName(): string {
    if (!this.UserDisplayName) {
      this.UserDisplayName = localStorage.getItem('otayo-user-displayname');
    }
    return this.UserDisplayName;
  }
  public getUserEmail(): string {
    if (!this.userEmail) {
      this.userEmail = localStorage.getItem('otayo-user-useremail');
    }
    return this.userEmail;
  }

  public logout(): void {
    this.token = '';
    window.localStorage.removeItem('mean-token');
    window.localStorage.removeItem('otayo-prod-name');
    window.localStorage.removeItem('otayo-user-role');
    window.localStorage.removeItem('otayo-user-displayname');
    window.localStorage.removeItem('otayo-user-useremail');
    this.router.navigateByUrl('/login');
  }

  public async login(useremail: string, userpassword: string): Promise<any> {
    return await this.http.post('https://otayo.com/wp-json/jwt-auth/v1/token',
      {
        'username': useremail,
        'password': userpassword
      })
      .subscribe(
        (val) => {
          this.val = val;
          if ('token' in this.val) {
            this.saveToken(this.val.token, this.val.user_nicename, this.val.user_role[0], this.val.user_display_name, this.val.user_email);
            this.producerInfo = this.val.user_nicename;
            this.userRole = this.val.user_role;
            this.UserDisplayName = this.val.user_display_name;
            this.userEmail = this.val.user_email;
            this.isvalid = true;
          } else {
            this.isvalid = false;
          }
        },
        response => {
          this.isvalid = false;
        },
        () => {
          this.router.navigateByUrl('/profile');
          return this.isvalid;
        }
      );
    // return this.isvalid;
  }

}
