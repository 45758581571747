import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Select2OptionData } from 'ng2-select2';
import { AuthenticationService } from '../authentication.service';
import { CsvService } from '../csv.service';
import {ExcelService} from '../excel.service';

@Component({
  selector: 'app-csv',
  templateUrl: './csv.component.html',
  styleUrls: ['./csv.component.css']
})
export class CsvComponent implements OnInit {

  public exampleData: Array<Select2OptionData>;

  allEvents: any;
  ordersCount: any;
  exportBtnText: any;
  progress: any;
  report: any;

  selectedEvent: any;
  selectedStartDate: any;
  selectedEndDate: any;
  selectedPos: any;
  selectedOderStatus: any;
  selectedDocumentTitle: any;
  selectDeselect: any;

  selectedColAttendeeFirstName: any;
  selectedColAttendeeLastName: any;
  selectedColAttendeeFullName: any;
  selectedColAttendeeEmail: any;
  selectedColPaymentDate: any;
  selectedColOrderNumber: any;
  selectedColOrderTotal: any;
  selectedColSubTotalOnce: any;
  selectedColTicketsFeeOnce: any;
  selectedColTotalFeeOnce: any;
  selectedColGatewayFeeOnce: any;
  selectedColOrderTotalOnce: any;
  selectedColPaymentGateway: any;
  selectedColOrderStatus: any;
  selectedColDiscountCode: any;
  selectedColTicketCode: any;
  selectedColTicketID: any;
  selectedColTicketType: any;
  selectedColTicketPrice: any;
  selectedColTicketFee: any;
  selectedColBuyerFirstName: any;
  selectedColBuyerLastName: any;
  selectedColBuyerFullName: any;
  selectedColBuyerEmail: any;
  selectedColCheckedIn: any;
  selectedColCheckIns: any;
  selectedColRefundNotes: any;
  selectedColRefundAmount: any;
  selectedColTerminalReceipt: any;
  selectedColPosOrderComments: any;
  selectedColOrderComments: any;
  selectedColPointOfSale: any;
  selectedColTelephone: any;

  constructor(private auth: AuthenticationService, private router: Router, private csvService: CsvService, private ngxService: NgxUiLoaderService, private excelService:ExcelService) {
    this.allEvents = false;
    this.setDefaults();
    this.ordersCount = false;
    this.exportBtnText = "Export to CSV";
    this.progress = 0;
    this.report = [];
  }

  ngOnInit() {
    let userRole = this.auth.getRole();
    if(userRole != 'administrator') {
      this.router.navigateByUrl('/profile');
    }
    else {
      // this.exampleData = [
      //   {
      //     id: 'basic1',
      //     text: 'Basic 1'
      //   },
      //   {
      //     id: 'basic2',
      //     disabled: true,
      //     text: 'Basic 2'
      //   },
      //   {
      //     id: 'basic3',
      //     text: 'Basic 3'
      //   },
      //   {
      //     id: 'basic4',
      //     text: 'Basic 4'
      //   }
      // ];
      this.ngxService.start();
      this.csvService.getAllEvents()
      .then(data => {
        this.allEvents = data;
        console.log(this.allEvents);
        this.ngxService.stop();
      })
      .catch(error => {
        this.allEvents = false;
        console.log(error);
        this.ngxService.stop();
      });
    }
  }

  setDefaults() {
    this.selectedEvent = 'all';
    this.selectedStartDate = '';
    this.selectedEndDate = '';
    this.selectedPos = 'all';
    this.selectedOderStatus = 'any';
    this.selectedDocumentTitle = 'Attendee List';
    this.selectDeselect = false;

    this.selectedColAttendeeFirstName = false;
    this.selectedColAttendeeLastName = false;
    this.selectedColAttendeeFullName = false;
    this.selectedColAttendeeEmail = false;
    this.selectedColPaymentDate = true;
    this.selectedColOrderNumber = true;
    this.selectedColOrderTotal = true;
    this.selectedColSubTotalOnce = true;
    this.selectedColTicketsFeeOnce = true;
    this.selectedColTotalFeeOnce = true;
    this.selectedColGatewayFeeOnce = true;
    this.selectedColOrderTotalOnce = true;
    this.selectedColPaymentGateway = true;
    this.selectedColOrderStatus = true;
    this.selectedColDiscountCode = true;
    this.selectedColTicketCode = false;
    this.selectedColTicketID = false;
    this.selectedColTicketType = true;
    this.selectedColTicketPrice = true;
    this.selectedColTicketFee = true;
    this.selectedColBuyerFirstName = true;
    this.selectedColBuyerLastName = true;
    this.selectedColBuyerFullName = true;
    this.selectedColBuyerEmail = true;
    this.selectedColCheckedIn = false;
    this.selectedColCheckIns = false;
    this.selectedColRefundNotes = true;
    this.selectedColRefundAmount = true;
    this.selectedColTerminalReceipt = true;
    this.selectedColPosOrderComments = true;
    this.selectedColOrderComments = true;
    this.selectedColPointOfSale = true;
    this.selectedColTelephone = true;
  }

  selectAll() {
    if (this.selectDeselect) {
      this.selectedColAttendeeFirstName = false;
      this.selectedColAttendeeLastName = false;
      this.selectedColAttendeeFullName = false;
      this.selectedColAttendeeEmail = false;
      this.selectedColPaymentDate = true;
      this.selectedColOrderNumber = true;
      this.selectedColOrderTotal = true;
      this.selectedColSubTotalOnce = true;
      this.selectedColTicketsFeeOnce = true;
      this.selectedColTotalFeeOnce = true;
      this.selectedColGatewayFeeOnce = true;
      this.selectedColOrderTotalOnce = true;
      this.selectedColPaymentGateway = true;
      this.selectedColOrderStatus = true;
      this.selectedColDiscountCode = true;
      this.selectedColTicketCode = false;
      this.selectedColTicketID = false;
      this.selectedColTicketType = true;
      this.selectedColTicketPrice = true;
      this.selectedColTicketFee = true;
      this.selectedColBuyerFirstName = true;
      this.selectedColBuyerLastName = true;
      this.selectedColBuyerFullName = true;
      this.selectedColBuyerEmail = true;
      this.selectedColCheckedIn = false;
      this.selectedColCheckIns = false;
      this.selectedColRefundNotes = true;
      this.selectedColRefundAmount = true;
      this.selectedColTerminalReceipt = true;
      this.selectedColPosOrderComments = true;
      this.selectedColOrderComments = true;
      this.selectedColPointOfSale = true;
      this.selectedColTelephone = true;
    } else {
      this.selectedColAttendeeFirstName = false;
      this.selectedColAttendeeLastName = false;
      this.selectedColAttendeeFullName = false;
      this.selectedColAttendeeEmail = false;
      this.selectedColPaymentDate = false;
      this.selectedColOrderNumber = false;
      this.selectedColOrderTotal = false;
      this.selectedColSubTotalOnce = false;
      this.selectedColTicketsFeeOnce = false;
      this.selectedColTotalFeeOnce = false;
      this.selectedColGatewayFeeOnce = false;
      this.selectedColOrderTotalOnce = false;
      this.selectedColPaymentGateway = false;
      this.selectedColOrderStatus = false;
      this.selectedColDiscountCode = false;
      this.selectedColTicketCode = false;
      this.selectedColTicketID = false;
      this.selectedColTicketType = false;
      this.selectedColTicketPrice = false;
      this.selectedColTicketFee = false;
      this.selectedColBuyerFirstName = false;
      this.selectedColBuyerLastName = false;
      this.selectedColBuyerFullName = false;
      this.selectedColBuyerEmail = false;
      this.selectedColCheckedIn = false;
      this.selectedColCheckIns = false;
      this.selectedColRefundNotes = false;
      this.selectedColRefundAmount = false;
      this.selectedColTerminalReceipt = false;
      this.selectedColPosOrderComments = false;
      this.selectedColOrderComments = false;
      this.selectedColPointOfSale = false;
      this.selectedColTelephone = false;
    }
  }

  setFilters() {
    let filters =  {
      selectedEvent: this.selectedEvent,
      selectedStartDate: this.selectedStartDate,
      selectedEndDate: this.selectedEndDate,
      selectedPos: this.selectedPos,
      selectedOderStatus: this.selectedOderStatus,
      selectedDocumentTitle: this.selectedDocumentTitle,
      selectedColAttendeeFirstName: this.selectedColAttendeeFirstName,
      selectedColAttendeeLastName: this.selectedColAttendeeLastName,
      selectedColAttendeeFullName: this.selectedColAttendeeFullName,
      selectedColAttendeeEmail: this.selectedColAttendeeEmail,
      selectedColPaymentDate: this.selectedColPaymentDate,
      selectedColOrderNumber: this.selectedColOrderNumber,
      selectedColOrderTotal: this.selectedColOrderTotal,
      selectedColSubTotalOnce: this.selectedColSubTotalOnce,
      selectedColTicketsFeeOnce: this.selectedColTicketsFeeOnce,
      selectedColTotalFeeOnce: this.selectedColTotalFeeOnce,
      selectedColGatewayFeeOnce: this.selectedColGatewayFeeOnce,
      selectedColOrderTotalOnce: this.selectedColOrderTotalOnce,
      selectedColPaymentGateway: this.selectedColPaymentGateway,
      selectedColOrderStatus: this.selectedColOrderStatus,
      selectedColDiscountCode: this.selectedColDiscountCode,
      selectedColTicketCode: this.selectedColTicketCode,
      selectedColTicketID: this.selectedColTicketID,
      selectedColTicketType: this.selectedColTicketType,
      selectedColTicketPrice: this.selectedColTicketPrice,
      selectedColTicketFee: this.selectedColTicketFee,
      selectedColBuyerFirstName: this.selectedColBuyerFirstName,
      selectedColBuyerLastName: this.selectedColBuyerLastName,
      selectedColBuyerFullName: this.selectedColBuyerFullName,
      selectedColBuyerEmail: this.selectedColBuyerEmail,
      selectedColCheckedIn: this.selectedColCheckedIn,
      selectedColCheckIns: this.selectedColCheckIns,
      selectedColRefundNotes: this.selectedColRefundNotes,
      selectedColRefundAmount: this.selectedColRefundAmount,
      selectedColTerminalReceipt: this.selectedColTerminalReceipt,
      selectedColPosOrderComments: this.selectedColPosOrderComments,
      selectedColOrderComments: this.selectedColOrderComments,
      selectedColPointOfSale: this.selectedColPointOfSale,
      selectedColTelephone: this.selectedColTelephone,
    };
    return filters;
  }

  async export() {
    this.progress = 25;
    this.ngxService.start();
    console.log('export');
    let filters = this.setFilters();

    // await this.csvService.getCount(filters).then(data => {
    //   console.log(data);
    //   this.ordersCount = data;
    //   if(this.ordersCount) {
    //     this.exportBtnText = `Exporting ${this.ordersCount.toLocaleString()} Orders..`;
    //   }
    //   this.progress = 75;
    // }).catch(error => {
    //   console.log(error);
    //   this.ngxService.stop();
    // });

    await this.csvService.getExported(filters, this.ordersCount).then(data => {
      console.log(data);
      this.report = data;
      // let tempData = Array.from(new Uint8Array(data));
      this.excelService.exportAsExcelFile(this.report, this.selectedDocumentTitle);
      // let count = this.ordersCount.toLocaleString();
      this.ordersCount = 0;
      this.exportBtnText = `Exported Orders, Export Again?`;
      this.ngxService.stop();
    }).catch(error => {
      console.log(error);
      this.ngxService.stop();
    });

    await this.csvService.getOldEvent(filters.selectedEvent)
    .then(async olddata => {
      console.log("-------------");
      console.log(olddata);
      filters.selectedEvent = olddata;
      await this.csvService.getExportedOld(filters, this.ordersCount).then(data => {
        // console.log(data);
        this.report = data;
        // let tempData = Array.from(new Uint8Array(data));
        this.excelService.exportAsExcelFile(this.report, this.selectedDocumentTitle);
        // let count = this.ordersCount.toLocaleString();
        this.ordersCount = 0;
        this.exportBtnText = `Exported Orders, Export Again?`;
        this.ngxService.stop();
      }).catch(error => {
        console.log(error);
        this.ngxService.stop();
      });
      this.ngxService.stop();
    })
    .catch(error => {
      console.log(error);
      this.ngxService.stop();
    });


  }

  viewData() {
    console.log('View data');
    let filters = this.setFilters();
  }

}
