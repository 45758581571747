import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { GoogleChartInterface } from 'ng2-google-charts/google-charts-interfaces';
import * as moment from 'moment';
import { AuthenticationService } from '../authentication.service';
import { ReportingService } from '../reporting.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  eventID: any;
  eventDetail: any;
  isloaded: any;
  isseated: any;
  showDiscountColumn: any;

  totalAllocated: any;
  totalSold: any;
  totalReserved: any;
  totalRemaining: any;
  totalPrice: any;
  totalWithDiscounts: any;
  totalDiscounts: any;
  totalDiscountsQty: any;

  pieData: any;
  lineData: any;
  columnData: any;
  pieChart: GoogleChartInterface;
  pieChart2: GoogleChartInterface;
  lineChart: GoogleChartInterface;
  columnChart: GoogleChartInterface;
  barCategoryChart: GoogleChartInterface;

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(private auth: AuthenticationService, private router: ActivatedRoute, private reportingservice: ReportingService, private route: Router, private ngxService: NgxUiLoaderService) {
    this.pieData = [['Ticket', 'Sale']];
    this.lineData = [['Day', 'Sales']];
    this.columnData = [];

    this.isloaded = false;
    this.isseated = false;
    this.totalAllocated = 0;
    this.totalSold = 0;
    this.totalReserved = 0;
    this.totalDiscounts = 0;
    this.totalDiscountsQty = 0;
    this.totalRemaining = 0;
    this.totalPrice = 0;
    this.totalWithDiscounts = 0;
    this.showDiscountColumn = false;
  }

  ngOnInit() {
    this.ngxService.start();
    this.dtOptions = {
      dom: 'Bfrtip',
      order: [],
      buttons: ['copy', 'csv', 'excel', 'pdf', 'print']
    };
    if (this.auth.getToken() == null) {
      this.route.navigateByUrl('/login');
    } else {
      this.eventID = this.router.snapshot.paramMap.get('id');
      // this.eventID = window.location.pathname.split("/").pop();
      this.getreport();
      // check if this.eventID has one of the events id in an array
      // if it does, show the discount column
      let eventToShowDiscountColumn = ["520479-520630", "520556-520636", "520586-520644", "520605-520651", "520622-520654", "524925-525319", "619593-620583", "571437-576023", "667010-667226", "667285-667369", "667566-667589", "683365-684003", "661608-661763", "661615-661779", "661621-661930", "693061-693298", "691794-691947", "715831-716661"];
      if(eventToShowDiscountColumn.indexOf(this.eventID) > -1) {
        this.showDiscountColumn = true;
      }
    }
  }

  async getreport() {
    await this.reportingservice.getreport(this.eventID).subscribe(val => {
      this.eventDetail = val;
      this.ngxService.stop();
      // console.log(this.eventDetail);
      // console.log("event_metas", this.eventDetail.event_metas);
      // console.log('orders', this.eventDetail.orders);
      // console.log(this.eventDetail.event_tickets_types);
      this.eventDetail.orders.forEach(order => {
        if (order.post_status == "wc-processing") {
          // console.log(this.totalWithDiscounts + ' += ' + order.order_meta.tc_payment_info.total + ' - ' + order.order_meta.tc_payment_info.fees_total);
          if (typeof order.order_meta.ordertotal != 'undefined') {
            this.totalWithDiscounts += parseFloat(order.order_meta.ordertotal);
          }
        }

        if (order.post_status == "order_paid") {
          // console.log(this.totalWithDiscounts + ' += ' + order.order_meta.tc_payment_info.total + ' - ' + order.order_meta.tc_payment_info.fees_total);

          if (typeof order.order_meta.tc_payment_info.subtotal != 'undefined') {
            this.totalWithDiscounts += parseFloat(order.order_meta.tc_payment_info.total) - parseFloat(order.order_meta.tc_payment_info.fees_total);
          }
        }
      });

      this.isseated = this.isSeatingEventcheck(this.eventDetail.event_metas.seating_type);

      this.eventDetail.event_tickets_types.forEach(ticket => {
        this.updateTotals(ticket.total, ticket.sold, ticket.reserved, ticket.total - ticket.sold - ticket.reserved, ticket.sold * ticket.price, ticket.discount, ticket.discountqty);
        this.pieData.push([ticket.name, ticket.sold]);
      });
      this.pieChart = {
        chartType: 'PieChart',
        dataTable: this.pieData,
        options: {
          title: 'Tickets Sales Per Category',
          is3D: true,
          width: '100%',
          height: 400
        },
      };

      this.pieChart2 = {
        chartType: 'PieChart',
        dataTable: [
          ['Title', 'Sale'],
          ['Remaining', this.totalRemaining],
          ['Sold', this.totalSold],
          ['Reserved', this.totalReserved]
        ],
        options: {
          title: 'Tickets Sales',
          is3D: true,
          width: '100%',
          height: 400
        },
      };

      let tempLineData = [];
      let tempStackedData = [];
      this.eventDetail.orders.forEach(order => {
        if (typeof tempLineData[Math.ceil(moment(order.post_date).week())] === 'undefined') {
          tempLineData[Math.ceil(moment(order.post_date).week())] = 0;
        }
        tempLineData[Math.ceil(moment(order.post_date).week())] += order.order_meta.tickets.length;
      });

      tempLineData.forEach((item, index) => {
        this.lineData.push([index, item]);
      });

      this.lineChart = {
        chartType: 'LineChart',
        dataTable: this.lineData,
        options: {
          title: 'Tickets Sales Trend (Per Week Of the Year)',
          legend: { position: 'bottom' },
          width: '100%',
          height: 400
        },
      };

      this.barCategoryChart = {
        chartType: 'ColumnChart',
        dataTable: this.pieData,
        options: {
          title: 'Category Tickets Sales',
          height: 500,
          legend: { position: 'bottom', maxLines: 3 },
          vAxis: { minValue: 0 },
          width: '100%'
        },
      };

      let tempCats = ['Week'];
      let tempstats = [];
      let check = true;
      for (var index in this.eventDetail.week_report) {
        if (!(this.eventDetail.week_report.hasOwnProperty(index))) continue;
        tempstats.push(index + 'th');
        var item = this.eventDetail.week_report[index];
        for (var typeindex in item) {
          if (!item.hasOwnProperty(typeindex)) continue;
          if (check) {
            tempCats.push(typeindex);
          }
          tempstats.push(item[typeindex]);
        }
        this.columnData.push(tempstats);
        tempstats = [];
        check = false;
      }

      this.columnData.unshift(tempCats);
      // console.log("columnData", this.columnData);
      this.columnChart = {
        chartType: 'ColumnChart',
        dataTable: this.columnData,
        options: {
          title: 'Tickets Sales Trend (Per Week Number Of Year)',
          isStacked: true,
          height: 500,
          legend: { position: 'bottom', maxLines: 3 },
          vAxis: { minValue: 0 },
          width: '100%'
        },
      };
      // console.log(val);
      this.isloaded = true;
    });
  }

  postTextReplace(status) {
    const re = /_/gi;
    const newstr = status.replace('wc-', ' ');
    return newstr.toUpperCase();
    // return status;
  }

  isSeatingEvent(type) {
    if (type == '') {
      return 'No';
    } else {
      return 'Yes';
    }
  }

  isSeatingEventcheck(type) {
    if (type == '') {
      return false;
    } else {
      return true;
    }
  }

  updateTotals(alloc, sold, resrv, remaining, price, discount, discountqty) {
    if (alloc) {
      this.totalAllocated += alloc;
    }
    if (sold)
      this.totalSold += sold;
    if (resrv)
      this.totalReserved += resrv;
    if (remaining)
      this.totalRemaining += remaining;
    if (price)
      this.totalPrice += price-discount;
    if (discount)
      this.totalDiscounts += discount;
    if (discountqty)
      this.totalDiscountsQty += discountqty;
  }

  formatDate(date) {
    let myMoment: any;
    myMoment = moment(date).format('MMMM Do YYYY, h:mm a');
    return myMoment;
  }

  formatDateSort(date) {
    let myMoment: any;
    myMoment = moment(date).format('YYYYMMDDHHmmss');
    return myMoment;
  }

  convertCharacters(container) {
    return container.replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;");
  }

  log(stat) {
    console.log(stat);
  }

  refreshCurrent() {
    this.columnData = [];

    this.isloaded = false;
    this.isseated = false;
    this.totalAllocated = 0;
    this.totalSold = 0;
    this.totalReserved = 0;
    this.totalRemaining = 0;
    this.totalPrice = 0;
    this.totalWithDiscounts = 0;
    this.totalDiscounts = 0;
    this.totalDiscountsQty = 0;
    this.getreport();
  }

}
