import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  response: boolean;

  constructor(private auth: AuthenticationService, private router: Router) {
    this.email = '';
    this.password = '';
    this.response = true;
  }

  async login() {
    if(this.email == '' && this.password == ''){
      this.response = false;
    } else {
      this.auth.login(this.email, this.password).then((data) => {
        this.response = true;
        this.router.navigateByUrl('/profile');
      }).catch((error) => {
        this.response = false;
      });
    }
  }

  ngOnInit() {
    if (this.auth.getToken() != null) {
      this.router.navigateByUrl('/profile');
    }
  }

}
