import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { ReportingService } from '../reporting.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { CsvService } from '../csv.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  events: any;

  dtOptions1: any = {};
  dtTrigger1: Subject<any> = new Subject();

  constructor(private auth: AuthenticationService, private router: Router, private reportingservice: ReportingService, private csvService: CsvService, private ngxService: NgxUiLoaderService) {
    this.events = '';
  }

  ngOnInit() {
    this.ngxService.start();
    this.dtOptions1 = {
      dom: 'Bfrtip',
      order: []
    };

    if (this.auth.getToken() == null) {
      this.router.navigateByUrl('/login');
    } else {
      let userRole = this.auth.getRole();
      if(userRole != 'administrator') {
        this.getEvents(this.auth.getProd());
      } else {
        this.csvService.getAllEvents()
        .then(data => {
          this.events = data;
          this.ngxService.stop();
        })
        .catch(error => {
          this.events = '';
          this.ngxService.stop();
          console.log(error);
        });
      }

    }
  }

  async getEvents(prod) {
     await this.reportingservice.getevents(prod).subscribe(val => {
      this.events = val;
      this.ngxService.stop();
      // console.log(this.events);
    });
  }

}
