import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  loggedIn: boolean;
  currentPage: string;
  userRole: any;
  constructor(private auth: AuthenticationService, private router: Router) {
    this.currentPage = this.router.url;
    this.loggedIn = false;
   }

   logout() {
     this.loggedIn = false;
     this.auth.logout();
     this.router.navigateByUrl('/');
   }

  ngOnInit() {
    if (this.auth.getToken() != null) {
      this.loggedIn = true;
      this.userRole = this.auth.getRole();
      console.log(this.userRole);
    }
  }

}
